import webviewFun from '@/utils/webview-fun';

const { getDeviceInfo } = webviewFun;

const getlang = async function (lang: string) {
  let res = await getDeviceInfo();
  lang = (res && res.res && res.res.language) || lang;

  if (lang === '' || lang === undefined) {
    lang =
      navigator.language ||
      //@ts-ignore
      navigator.userLanguage ||
      //@ts-ignore
      navigator.browserLanguage ||
      //@ts-ignore
      navigator.systemLanguage; //常规浏览器语言和IE浏览器
  }
  lang = lang.substr(0, 2); //截取lang前2位字符

  if (lang === 'ug') {
    lang = 'ar';
  }

  if (lang != 'ar' && lang != 'zh' && lang != 'tr' && lang != 'jp') {
    lang = 'en';
  }

  return lang || 'en';
};

const setlang = function (language: string, lang: any) {
  switch (language) {
    case 'ar':
      return lang.ar || lang.en;
    case 'tr':
      return lang.tr || lang.en;
    default:
      return lang.en;
  }
};

export default { getlang, setlang };
