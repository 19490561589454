import { t } from '@/utils/i18n'

const langClass = {
  zh: 'zhlang',
  en: 'enlang',
  ar: 'arlang',
  tr: 'trlang',
};

const langFontClass = {
  en: 'enfont',
  ar: 'arfont',
  tr: 'trfont',
};


export default { REQUEST_EXPIRED: t('请求已过期'), langClass, langFontClass };
