/**
 * 返回科学计数法表示的小数位数，如digitLength(3e-7)返回7，因为3e-7是0.0000003的科学计数法表示
 *
 * @param {*} num
 * @returns
 */
function digitLength(num = 0) {
  const eSplit = num.toString().split(/[eE]/)
  const len = (eSplit[0].split('.')[1] || '').length - +(eSplit[1] || 0)

  return len > 0 ? len : 0
}

/**
 * 把小数转成整数，支持科学计数法。如果是小数则放大成整数
 * @param {*number} num 输入数
 */
function float2Fixed(num = 0) {
  if (num.toString().indexOf('e') === -1) {
    return Number(num.toString().replace('.', ''))
  }

  const dLen = digitLength(num)

  return dLen > 0 ? num * 10 ** dLen : num
}

/**
 * 精确加法
 */
function plus(...args: any[]): number {
  const [num1, num2] = args
  const others = args.slice(2)

  if (others.length) {
    return plus(...[plus(num1, num2), ...others])
  }

  const baseNum = 10 ** Math.max(digitLength(num1), digitLength(num2))

  return (times(num1, baseNum) + times(num2, baseNum)) / baseNum
}

/**
 * 精确减法
 */
function minus(...args: any[]): number {
  const [num1, num2] = args
  const others = args.slice(2)

  if (others.length) {
    return minus(...[minus(num1, num2), ...others])
  }

  const baseNum = 10 ** Math.max(digitLength(num1), digitLength(num2))

  return (times(num1, baseNum) - times(num2, baseNum)) / baseNum
}
/**
 * 精确乘法
 */
function times(...args: number[]): number {
  const [num1, num2] = args
  const others = args.slice(2)

  if (others.length) {
    return times(...[times(num1, num2), ...others])
  }

  const num1Changed = float2Fixed(num1)
  const num2Changed = float2Fixed(num2)
  const baseNum = digitLength(num1) + digitLength(num2)
  const leftValue = num1Changed * num2Changed

  return leftValue / 10 ** baseNum
}

/**
 * 精确除法
 */
function divide(...args: number[]): number {
  const [num1, num2] = args
  const others = args.slice(2)

  if (others.length) {
    return divide(...[divide(num1, num2), ...others])
  }

  const num1Changed = float2Fixed(num1)
  const num2Changed = float2Fixed(num2)

  return times(
    num1Changed / num2Changed,
    10 ** (digitLength(num2) - digitLength(num1)),
  )
}

/**
 * 四舍五入
 */
/**
 *
 *
 * @param {*} num 浮点数
 * @param {*} ratio 小数位数
 * @returns
 */
function round(num: number, ratio: number) {
  const base = 10 ** ratio

  return divide(Math.round(times(num, base)), base)
}

/**
 * 金额格式化：千分位+两位小数
 * @amount {Float} 浮点数
 * @return {Str}
 */
function formatAmount(amount: any) {
  return String(round(Number(amount), 2)).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**
 * 截取整数部分多少位
 *
 * @param {*} value 要截取的数字
 * @param {*} figures 截取位数
 * @param {string} [trimType='low'] 截取类型，low表示从低位开始截取，high表示从高位开始截取
 * @returns {number} 截取后的数字
 */
function trimInteger(
  value: number | string,
  figures: number,
  trimType: string = 'high',
) {
  const [integer, decimal] = value.toString().split('.')

  const newInteger =
    trimType === 'low' ? integer.slice(-figures) : integer.slice(0, figures)

  if (decimal) {
    return Number(`${newInteger}.${decimal}`)
  }

  return Number(`${newInteger}`)
}

/**
 * 只舍不入
 * @decimalPlace {Int} 小数位置
 * @originalNum {Float} 浮点数
 * @return {Float}
 */
function getDown(decimalPlace: number, originalNum: number) {
  const originalDecimalPlace = getDecimalPlace(originalNum)
  const decimal =
    originalDecimalPlace - decimalPlace >= 0
      ? originalDecimalPlace - decimalPlace
      : 0
  const str = originalNum.toString().split('.').join('')
  let float = getFloatNum(str, decimal)

  float = Math.trunc(Number(float))
  if (decimalPlace - originalDecimalPlace > 0) {
    float *= 10 ** (decimalPlace - originalDecimalPlace)
  }

  return getFloatNum(String(float), decimalPlace)
}

/**
 * 获取小数位置，支持科学记数法
 * @param {Float} num
 * @return {Int} decimal place
 */
function getDecimalPlace(num: number) {
  const eSplit = num.toString().split(/[eE]/)
  const len = (eSplit[0].split('.')[1] || '').length - +(eSplit[1] || 0)
  return len > 0 ? len : 0
}

/**
 * 根据数字和小数位置生成浮点数
 * @param {String} string: the number of the value
 * @param {Int} decimal: the decimal place of the value
 * @return {Float} real value
 */
function getFloatNum(str: string, decimalPlace: number) {
  if (str === '') return ''

  const decimal = Number(decimalPlace)
  const arr = String(str).split('')
  let negative = false

  if (arr[0] === '-') {
    negative = true
    arr.splice(0, 1)
  }

  while (arr.length <= decimal) {
    arr.unshift('0')
  }

  if (decimal) {
    arr.splice(-Number(decimal), 0, '.')
  }

  if (negative) {
    arr.unshift('-')
  }

  return Number(parseFloat(arr.join('')))
}

/**
 * 保留小数位
 * @param value 数字
 * @param decimalPlaces 小数位
 * @returns
 */
function formatNumber(value: number, decimalPlaces: number): number {
  if (isNaN(value) || isNaN(decimalPlaces) || decimalPlaces < 0) {
    return value
  }

  const fixedNumber = Number(value).toFixed(decimalPlaces)
  return Number(fixedNumber)
}

/**
 * 数字转千分位
 * @param value
 * @returns
 */
function formatCurrency(value: number): string | number {
  if (isNaN(value) || !value) return value

  const parts = value.toString().split('.')
  const wholePart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const demicalPart = parts[1]
  return demicalPart ? `${wholePart}.${parts[1]}` : wholePart
}

/**
 * 转换千分位，并且保留小数
 * @param value
 * @param decimalPlaces
 * @returns
 */
function moneyFormat(
  value: number,
  decimalPlaces: number = 2,
): string | number {
  if (isNaN(value) || !value) return value
  const fixedNumber = formatNumber(value, decimalPlaces)
  return formatCurrency(fixedNumber)
}

/**
 * 只入不舍
 * @decimalPlace {Int} 小数位置
 * @originalNum {Float} 浮点数
 * @return {Float}
 */
function getCarry(decimalPlace: number, originalNum: number) {
  const originalDecimalPlace = getDecimalPlace(originalNum)
  const decimal =
    originalDecimalPlace - decimalPlace >= 0
      ? originalDecimalPlace - decimalPlace
      : 0
  const str = originalNum.toString().split('.').join('')
  let float = getFloatNum(str, decimal)

  float = Math.ceil(Number(float))
  if (decimalPlace - originalDecimalPlace > 0) {
    float *= 10 ** (decimalPlace - originalDecimalPlace)
  }

  return getFloatNum(String(float), decimalPlace)
}

/**
 * 截取若干位小数
 * @param {Float} num
 * @param {Int} decimal
 * @return {Float} num
 */
const getFloat = (num: number, decimal: number) => {
  const multiples = num * 10 ** decimal
  const floor = Math.floor(multiples)

  const arr = String(floor).split('')
  let floatNum = ''
  let negative = false

  if (arr[0] === '-') {
    negative = true
    arr.splice(0, 1)
  }

  while (arr.length <= decimal) {
    arr.unshift('0')
  }

  if (decimal) {
    arr.splice(-Number(decimal), 0, '.')
  }

  if (negative) {
    arr.unshift('-')
  }

  floatNum = arr.join('')
  return floatNum
}

export default {
  plus,
  minus,
  times,
  divide,
  formatAmount,
  round,
  trimInteger,
  getDown,
  getDecimalPlace,
  formatNumber,
  formatCurrency,
  moneyFormat,
  getCarry,
  getFloat,
}
