function matchString(str: string, arr: string[]) {
  if (str === '' || arr.length === 0) {
    return false;
  }
  for (let v = 0; v < arr.length; v++) {
    if (str.indexOf(arr[v]) > 0) {
      return true;
    }
  }
  return false;
}

function isWeixin() {
  // 判断是否微信
  const ua = navigator.userAgent.toLowerCase();
  const arr = new Array('micromessenger');
  return matchString(ua, arr);
}

// 是否苹果客户端
function isIphone() {
  const ua = navigator.userAgent.toLowerCase();
  const arr = new Array('ipad', 'iphone', 'ipod');
  return matchString(ua, arr);
}

// 是否Android
function isAndroid() {
  const ua = navigator.userAgent.toLowerCase();
  const arr = new Array('android');
  return matchString(ua, arr);
}

function isQQ() {
  const ua = navigator.userAgent.toLowerCase();
  const arr = new Array('qq');
  return matchString(ua, arr);
}

function isWeibo() {
  const ua = navigator.userAgent.toLowerCase();
  const arr = new Array('weibo');
  return matchString(ua, arr);
}

function isHuawei() {
  const ua = navigator.userAgent.toLowerCase();
  const arr = ['huawei', 'honor', 'vog-l29'];
  return matchString(ua, arr);
}

function huaWeiOrAndroid() {
  const ua = navigator.userAgent.toLowerCase();
  const arr = ['huawei', 'honor', 'vog-l29'];
  const ua2 = navigator.userAgent.toLowerCase();
  const arr2 = new Array('android');

  const isHuawei = matchString(ua, arr);
  const isAndroid = matchString(ua2, arr2);

  return isHuawei ? isHuawei : isAndroid;
}

export default {
  isWeixin,
  isIphone,
  isAndroid,
  isQQ,
  isWeibo,
  isHuawei,
  huaWeiOrAndroid,
};
