export const configMap = {
  development: {
    rootPath: 'https://api-test.thechatie.com',
    realEnv: 'development',
    host: 'https://hoby-web-hybrid-dev.pages.dev',
    hobyWebHost: 'https://web-react-dev.hoby.chat', // 旧项目host地址
  },
  // 灰度
  testing: {
    rootPath: 'https://api-gray2.thechatie.com',
    realEnv: 'testing',
    host: 'https://hoby-web-hybrid-test.pages.dev',
    hobyWebHost: 'https://web-react-dev.hoby.chat', // 旧项目host地址
  },
  production: {
    rootPath: 'https://api.hoby.chat',
    realEnv: 'production',
    host: 'https://web-hy.hoby.chat',
    hobyWebHost: 'https://web-react.hoby.chat', // 旧项目host地址
  },
};

type Environment = 'development' | 'testing' | 'production';
const NODE_ENV: Environment = process.env.NODE_ENV as Environment;

export function getConfig(env?: 'development' | 'testing' | 'production') {
  const config = configMap[env || NODE_ENV] || configMap['production'];
  const defaultConfig = {
    language: 'en',
    designWidth: 375,
    projectName: 'hybrid',
  };

  return Object.assign(defaultConfig, config);
}
