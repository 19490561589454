
// import apis from '@/apis';
// import { useNavigate, useLocation } from 'react-router-dom'
import {
  // utils,
  routesMap,
} from '@/utils'
// import { useUserInfoStore } from '@/stores'
import { Navigate } from 'react-router-dom'
// import { useEffect, useState } from 'react'
import { useUserInfo } from '@/hooks/userContext'


// const { to } = utils
// const { user: userApi } = apis
function RouterGuard({ children }: any) {
  // const userInfoStore = useUserInfoStore()
  const { userInfo, loading } = useUserInfo()

  // 获取当前用户信息
  // async function getMyAccountInfo () {
  //   const [err, res] = await to(
  //     userApi.getMyAccountInfo({}),
  //   )

  //   if (err) return

  //   setGuildId(res.res.guildId)
  //   userInfoStore.setUserInfo(res.res)
  // }

  // 使用 useEffect 在组件挂载时调用异步函数
  // useEffect(() => {
  //   console.log('RouterGuard')
  //   getMyAccountInfo()
  // })

  // 如果数据还在加载，显示 loading 页面
  if (loading) {
    console.log('loading')
    return '';
  }

  if (userInfo.guildId > 0) {
    return children
  } else {
    // 没有加入公会跳转到公会列表
    return <Navigate to={ `${routesMap.GuildList}${location.search}&source=0&isClose=1` } replace />
  }
}

export default RouterGuard