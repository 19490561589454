import React from 'react';
import App from '@/pages/App'
// @ts-ignore
import RouterGuard from '@/components/RouterGuard'

const Guild = React.lazy(() => import('@/pages/Guild'))
const GuildList = React.lazy(() => import('@/pages/Guild/list'))
const MemberApply = React.lazy(() => import('@/pages/Guild/apply'))
const Member = React.lazy(() => import('@/pages/Guild/member'))
const Invite = React.lazy(() => import('@/pages/Guild/invite'))
const Demo = React.lazy(() => import('@/pages/Guild/demo'))

export interface Route {
  path: string
  name: string
  element: JSX.Element
  state?: any
  [key: string]: any
}

export interface RoutesMap {
  [key: string]: string
}

const routes: Route[] = [
  {
    path: '/',
    name: 'App',
    title: 'Hoby',
    element: (<App />),
  },
  {
    path: '/guild',
    name: 'Guild',
    title: '公会主页',
    element: (<RouterGuard><Guild /></RouterGuard>),
    // element: (<Guild />),
  },
  {
    path: '/guild/list',
    name: 'GuildList',
    title: '公会列表',
    element: (<GuildList />),
  },
  {
    path: '/guild/apply',
    name: 'GuildApply',
    title: '申请加入公会列表',
    element: (<MemberApply />),
  },
  {
    path: '/guild/member',
    name: 'GuildMember',
    title: '成员列表',
    element: (<Member />),
  },
  {
    path: '/guild/invite',
    name: 'GuildInvite',
    title: '邀请粉丝',
    element: (<Invite />),
  },
  {
    path: '/guild/demo',
    name: 'demo',
    title: 'demo',
    element: (<Demo />),
  },
]

// 用来做路由导航时路径名和路径的映射
export const routesMap: RoutesMap = routes.reduce(
  (acc: RoutesMap, cur: Route) => {
    const { path, name } = cur
    acc[name] = path
    return acc
  },
  {},
)

export default routes
