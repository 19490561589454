import { getConfig } from '@/config'

/**
 * 根据不同的运行环境，获取永久存储
 * @param key
 * @returns
 */
function getLocalStorage(key: string, isParse: boolean = false) {
  const prefix = getConfig().projectName + '_'
  let value = window.localStorage.getItem(prefix + key) || ''

  if (isParse && value) {
    return JSON.parse(value)
  }
  return value
}

/**
 * 根据不同的运行环境，设置永久存储
 * @param key
 * @returns
 */
function setLocalStorage(key: string, value: any) {
  const prefix = getConfig().projectName + '_'
  if (typeof value !== 'string') {
    value = JSON.stringify(value)
  }

  window.localStorage.setItem(prefix + key, value)
}

/**
 * 根据不同的运行环境，移除某一项存储
 * @param key
 */
function removeLocalStorage(key: string) {
  const prefix = getConfig().projectName + '_'
  window.localStorage.removeItem(prefix + key)
}

export default {
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage,
}
