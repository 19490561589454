import webviewFun from '@/utils/webview-fun';
import cal from './cal';
import constant from '@/constant';
import storageUtil from '@/utils/localStorage'

const { getUserInfo } = webviewFun;
const { common } = constant;
const { REQUEST_EXPIRED } = common;
/*
 * 将Promisr的结果和错误，转化为一个数组返回。
 * @params {Promise}
 * @return {array} [err, res]
 */
const to = (promise: Promise<any>) =>
  promise.then((res) => [null, res]).catch((err) => [err, null])

/**
 *  延迟一定时间，单位毫秒。
 */
const sleep = async (time: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, time);
  });

// 设置 accesstoken 和 uid
const setUidToken = async function (query: any) {
  let uid = null;
  let accesstoken = null;
  if (parseInt(query.uid) > 0 && query.accesstoken && query.accesstoken != '') {
    uid = query.uid;
    accesstoken = query.accesstoken;
  } else {
    let userInfo = await getUserInfo();
    if (
      userInfo &&
      userInfo.res &&
      userInfo.res.uid > 0 &&
      userInfo.res.accesstoken &&
      userInfo.res.accesstoken != ''
    ) {
      uid = userInfo.res.uid;
      accesstoken = userInfo.res.accesstoken;
    }
  }

  if (isNaN(parseInt(uid))) {
    return 0;
  }

  storageUtil.setLocalStorage('accessToken', accesstoken);
  storageUtil.setLocalStorage('uid', uid);
  return parseInt(uid);
};

/**
 * 数字转换
 * @param {*} number
 * @returns
 */
const numberThumbnail = (number: number) => {
  const num = number / 10; // 接口返回的礼物积分放大了十倍
  if (num < 1000) {
    return num;
  }

  if (num >= Math.pow(10, 3) && num <= 999999) {
    const codyNum = cal.divide(num, Math.pow(10, 3));
    const decimalIndex = String(codyNum).indexOf('.');
    // 只显示一位小数
    if (decimalIndex > -1) {
      return String(codyNum).substring(0, decimalIndex + 2) + 'K';
    }
    return codyNum + 'K';
  }

  if (num >= Math.pow(10, 6) && num <= 999999999) {
    const codyNum = cal.divide(num, Math.pow(10, 6));
    const decimalIndex = String(codyNum).indexOf('.');
    // 只显示一位小数
    if (decimalIndex > -1) {
      return String(codyNum).substring(0, decimalIndex + 2) + 'M';
    }
    return codyNum + 'M';
  }

  if (num > 999999999) {
    return '999.9M+';
  }
};

// 获取文件后缀
const getFileExtension = (url: string) => {
  try {
    // 从 URL 中提取文件名部分
    const filename = url.substring(url.lastIndexOf('/') + 1);
    // 确保 URL 包含文件名
    if (filename.indexOf('.') === -1) {
      throw new Error('No file extension found');
    }
    // 从文件名中提取后缀名
    const extension = filename.substring(filename.lastIndexOf('.') + 1);

    return extension;
  } catch (error) {
    console.error('Invalid URL', error);
  }
};

// 获取媒体类型
const getMediaType = (showType: string, showUrl: string) => {
  if (showType) return showType;
  return getFileExtension(showUrl);
};

function getUrlParameters(url: string) {
  if (!url) return;
  const paramsObject: { [key: string]: string } = {};

  // 找到问号后面的部分
  const queryStringIndex = url.indexOf('?');
  if (queryStringIndex !== -1) {
    const queryString = url.slice(queryStringIndex + 1);

    // 分割参数
    const pairs = queryString.split('&');

    pairs.forEach((pair) => {
      const [key, value] = pair.split('=');
      if (key) {
        // 解码参数并添加到对象中
        paramsObject[decodeURIComponent(key)] = value
          ? decodeURIComponent(value)
          : '';
      }
    });
  }

  return paramsObject;
}

// 检测是否支持webp
const checkWebPFeature = async (feature: 'lossy' | 'lossless' | 'alpha') => {
  const kTestImages = {
    lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
    lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
    alpha:
      'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
    animation:
      'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
  };

  return new Promise((resolve) => {
    const img = new Image();
    img.onload = function () {
      const result = img.width > 0 && img.height > 0;
      resolve(result);
    };
    img.onerror = function () {
      resolve(false);
    };
    img.src = 'data:image/webp;base64,' + kTestImages[feature];
  });
};

interface ILangItem {
  name: string;
  image_url: string;
  name_en: string;
  image_url_en: string;
  [key: string]: any;
}

const getLangItemName = (item: ILangItem) => {
  const language = storageUtil.getLocalStorage('language') || 'en';
  return item['name_' + language] || item.name;
};

const getLangImgUrl = (item: ILangItem) => {
  const language = storageUtil.getLocalStorage('language') || 'en';
  return item['image_url_' + language] || item.image_url;
};

/**
 * 是否为Null类型
 *
 * @param {*} obj
 * @returns
 */
function isNull(obj: any) {
  return Object.prototype.toString.call(obj) === '[object Null]'
}

/**
 * 是否为Undefined类型
 *
 * @param {*} obj
 * @returns
 */
function isUndefined(obj: any) {
  return Object.prototype.toString.call(obj) === '[object Undefined]'
}

/**
 * 数据是否为空
 * @param obj
 * @returns
 */
function isExist(obj: any) {
  return !isNull(obj) && !isUndefined(obj)
}

/**
 * 是否为空对象
 *
 * @param {*} obj
 * @returns
 */
function isEmptyObject(obj: any) {
  return isObject(obj) && Object.keys(obj).length === 0
}

/**
 * 是否为空数组
 *
 * @param {*} arr
 * @returns
 */
function isEmptyArray(arr: any) {
  return isArray(arr) && arr.length === 0
}

/**
 * 是否为Array类型
 *
 * @param {*} obj
 * @returns
 */
function isArray(obj: any) {
  return Object.prototype.toString.call(obj) === '[object Array]'
}

/**
 * 是否为Object类型
 *
 * @param {*} obj
 * @returns
 */
function isObject(obj: any) {
  return Object.prototype.toString.call(obj) === '[object Object]'
}
// 比较版本
// 返回 0 如果两个版本相等。
// 返回 1 如果第一个版本大于第二个版本。
// 返回 -1 如果第一个版本小于第二个版本。
function compareVersion(version1: string, version2: string) {
  const v1 = version1.split('.').map(Number);
  const v2 = version2.split('.').map(Number);

  const length = Math.max(v1.length, v2.length);

  for (let i = 0; i < length; i++) {
    const num1 = v1[i] || 0; // 如果没有该部分，默认为 0
    const num2 = v2[i] || 0; // 如果没有该部分，默认为 0

    if (num1 > num2) {
      return 1;
    }
    if (num1 < num2) {
      return -1;
    }
  }

  return 0;
}

/**
 * 获取url中的查询参数
 * @returns
 */
function getQuerySearch() {
  const queryString = window.location?.search || ''
  return queryString
}

/**
 * 解决请求竞态的问题，只有最后一次请求返回请求结果
 * @param {*} fn
 * @returns
 */
function onlyLastRequest(fn: (args: any) => Promise<any>) {
  let requestTime = 0

  return function requestFn(...args: any[]) {
    return new Promise((resolve, reject) => {
      const currentRequestTime = Date.now()
      requestTime = currentRequestTime

      fn(args)
        .then((res) => {
          if (currentRequestTime !== requestTime) {
            reject(REQUEST_EXPIRED)
            return
          }

          resolve(res)
        })
        .catch(reject)
    })
  }
}

/**
 * 函数防抖
 *
 * @param {function} fn 要执行的函数
 * @param {number} wait 等待时间
 * @param {boolean} immediate 是否立即执行，immediate为true时：立即执行，停止触发n秒后，才可以重新触发执行。immediate为false时：停止触发n秒后才执行
 * @returns {function}
 */
// eslint-disable-next-line @typescript-eslint/ban-types
function debounce(fn: Function, ...args1: any[]): (...args: any[]) => void {
  let [wait, immediate] = args1

  if (wait === void 0) {
    wait = 500
    immediate = false
  }

  if (immediate === void 0) {
    immediate = false
  }

  let timer: any = null

  return function debounceFn(...args2: any[]) {
    if (timer !== null) {
      clearTimeout(timer)
    }

    if (immediate) {
      const callNow = timer === null
      timer = setTimeout(() => {
        timer = null
      }, wait)

      if (callNow) {
        fn.apply(fn, args2)
      }
    } else {
      timer = setTimeout(() => {
        fn.apply(fn, args2)
        timer = null
      }, wait)
    }
  }
}

/**
 * 函数节流
 *
 * 函数在一段时间内多次触发只会执行第一次，在这段时间结束前，不管触发多少次也不会执行函数。
 *
 * @param {*} fn
 * @param {number} [gapTime=500]
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
function throttle(
  fn: (...args: any[]) => any,
  gapTime: number = 500,
): (...args: any[]) => void {
  let lastTime: number = 0

  // 返回新的函数
  return function throttleFn(...args: any[]) {
    const nowTime: number = Date.now()

    // 函数执行条件
    if (!lastTime || nowTime - lastTime > gapTime) {
      fn.apply(fn, args) // 将this和参数传给原函数
      lastTime = nowTime
    }
  }
}


export default {
  to,
  sleep,
  setUidToken,
  numberThumbnail,
  getFileExtension,
  getMediaType,
  checkWebPFeature,
  getUrlParameters,
  getLangItemName,
  getLangImgUrl,
  compareVersion,
  isArray,
  isEmptyArray,
  isEmptyObject,
  isObject,
  isNull,
  isUndefined,
  isExist,
  getQuerySearch,
  onlyLastRequest,
  debounce,
  throttle,
};
