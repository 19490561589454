import { useDispatch } from 'react-redux'

/**
 * 分发action
 * @param actions 
 * @returns 
 */
export function useDispatchActions(actions: any) {
  const dispatch = useDispatch()
  const actionKeys: string[] = Object.keys(actions)
  const actionPool: any = {}
  actionKeys.forEach((key: string) => {
    actionPool[key] = (payload: any) => dispatch(actions[key](payload))
  })
  return actionPool
}

/**
 * 根据payload设置state，利用的对象的引用特性
 * @param state 
 * @param payload 
 */
export function setStoreState(state: any, payload: any, initState: any = {}) {
  const data = payload || initState
  for (const key in data) {
    state[key] = data[key]
  }
}