import { useState } from 'react'
// import styles from './index.module.less'

function App() {
  const [count, setCount] = useState(0)
  return (
    <>
      <h1 onClick={() => setCount((count) => count + 1)}>
        Hi Hoby {count}
      </h1>
    </>
  )
}

export default App
