import http from '../http'

export default {
  // 获取公会信息
  getGuildInfo(data: object) {
    return http.post('/web/guild/info', data);
  },
  // 获取公会列表
  getGuildList(data: object) {
    return http.post('/web/guild/list', data);
  },
  // 获取公会列表
  useQueryGuildList(data: object) {
    return http.usePost('/web/guild/list', data);
  },
  // 申请加入公会
  applyGuildJoin(data: object) {
    return http.post('/web/guild/apply_join', data);
  },
  // 获取申请列表
  getGuildApplyList(data: object) {
    return http.post('/web/guild/apply_list', data);
  },
  // 处理申请
  handleGuildApplyProcess(data: object) {
    return http.post('/web/guild/apply_process', data);
  },
  // 获取公会成员列表
  getGuildMemberList(data: object) {
    return http.post('/web/guild/member', data);
  },
  // 移出公会成员
  removeGuildMember(data: object) {
    return http.post('/web/guild/member/remove', data);
  },
  // 设置或取消管理员
  setGuildMemberRole(data: object) {
    return http.post('/web/guild/member/set_role', data);
  },
  // 获取粉丝列表
  getGuildFansList(data: object) {
    return http.post('/web/guild/can_invite_fans', data);
  },
  // 邀请粉丝加入公会
  inviteGuildFans(data: object) {
    return http.post('/web/guild/invite_fans', data);
  },
}
