import dsBridge from 'dsbridge';
import brower from '@/utils/brower';
import type { MediaOption } from '@/types/common'

// 获取用户信息
const getUserInfo = function () {
  try {
    if (brower.isIphone()) {
      let data = dsBridge.call('getUserInfo', '') || {};
      if (data && data != '') {
        data = JSON.parse(data);
      }
      return data;
    } else {
      let data = (dsBridge.call('getUserInfo', '')) || {};
      if (data && data != '') {
        data = JSON.parse(data);
      }
      return data;
    }
  } catch (e) {
    return {};
  }
};

// 获取用户设备信息
const getUserDeviceInfo = async function () {
  try {
    if (brower.isIphone()) {
      let d = dsBridge.call('getUserDeviceInfo', '') || {};
      if (d && d != '') {
        d = JSON.parse(d);
      }
      return d;
    } else {
      let d = dsBridge.call('getUserDeviceInfo', '') || {};
      console.log(d);
      if (d && d != '') {
        d = JSON.parse(d);
      }
      return d;
    }
  } catch (e) {
    return {};
  }
};

// 获取设备信息
const getDeviceInfo = function () {
  if (dsBridge.hasNativeMethod('getDeviceInfo')) {
    if (brower.isIphone()) {
      let d = dsBridge.call('getDeviceInfo', '') || {};
      if (d && d != '') {
        d = JSON.parse(d);
      }
      return d;
    } else {
      let d = dsBridge.call('getDeviceInfo', '') || {};
      console.log(d);
      if (d && d != '') {
        d = JSON.parse(d);
      }
      return d;
    }
  }

  return {};
};

// true - 表示小于等于指出的版本
const versionCompare = async function (version: any, platform: number) {
  console.log('对比版本', version, platform);
  if (version > 0 || version != '') {
    let NewversionArr = [];
    let deviceInfo = null;
    let res1 = await getDeviceInfo();
    console.log(res1, '设备信息1');

    let res2 = await getUserDeviceInfo();
    console.log(res2, '用户设备信息2');

    if (res1 && res1.code == 0) {
      deviceInfo = await getDeviceInfo();
    }
    if (res2 && res2.code == 0) {
      deviceInfo = await getUserDeviceInfo();
    }

    console.log('设备信息', deviceInfo);
    if (brower.isIphone() && platform == 1) {
      let versionArr = version.split('.');
      console.log('版本array', versionArr);

      if (deviceInfo && deviceInfo.res && deviceInfo.res.version) {
        if (deviceInfo.res.version == version) {
          return false;
        }
        if (
          deviceInfo.res.version == '0.0.0' ||
          deviceInfo.res.version == '0.0.0.0'
        ) {
          return true;
        }
        NewversionArr = deviceInfo.res.version.split('.');
      }

      console.log('新旧版本对比', versionArr, NewversionArr);
      if (NewversionArr.length > 2 && versionArr.length > 2) {
        if (parseInt(NewversionArr[0]) > parseInt(versionArr[0])) {
          return true;
        } else {
          if (parseInt(NewversionArr[1]) > parseInt(versionArr[1])) {
            return true;
          } else {
            if (parseInt(NewversionArr[2]) >= parseInt(versionArr[2])) {
              return true;
            } else {
              return false;
            }
          }
        }
      }
    }

    if (platform == 2) {
      if (deviceInfo && deviceInfo.res && deviceInfo.res.versionCode) {
        if (parseInt(version) == parseInt(deviceInfo.res.versionCode)) {
          return false;
        }

        if (parseInt(version) < parseInt(deviceInfo.res.versionCode)) {
          return true;
        }
      }
    }
  }
  return false;
};

//改变导航栏头部标题
const setTabBar = async function (type = '1') {
  try {
    if (brower.isIphone()) {
      dsBridge.call('setStatusBar', type);
    } else {
      dsBridge.call('setStatusBar', type);
    }
    return '';
  } catch (e) {
    return e;
  }
};

// -------- jump前缀跳转app内的方法 --------
// 提示：IOS之前写的跳转方法函数每一个都是不同的，安卓是统一的
// 后续统一为：startBySchema

const hiddenBackButton = async (type: number) => {
  if (brower.isIphone()) {
    dsBridge.call('hiddenBackButton', type);
  } else {
    dsBridge.call('hiddenBackButton', type);
  }
};

const jumpToFamily = async function (familyId: string) {
  console.log('Jump-familyId', familyId);
  try {
    if (familyId !== '') {
      let req = {
        code: 0,
        res: `chatie://family/center?familyId=${familyId}`,
      };

      if (brower.isIphone()) {
        dsBridge.call('jumpToFamily', familyId);
      } else {
        dsBridge.call('startBySchema', JSON.stringify(req));
      }
    }
    return '';
  } catch (e) {
    return {};
  }
};

const jumpToUser = async function (uid: string) {
  try {
    if (uid !== '0' && uid !== '') {
      let req = {
        code: 0,
        res: `chatie://user/profile?uid=${uid}`,
      };
      if (brower.isIphone()) {
        dsBridge.call('jumpToUser', uid);
      } else {
        dsBridge.call('startBySchema', JSON.stringify(req));
      }
    }
    return '';
  } catch (e) {
    return {};
  }
};

//跳转broadcast
const broadCast = async function (uid: string) {
  console.log('user', uid);
  try {
    if (uid !== '0' && uid !== '') {
      let req = {
        code: 0,
        res: `chatie://main/broadcast`,
      };
      if (brower.isIphone()) {
        dsBridge.call('gotoBroadcast', uid);
      } else {
        dsBridge.call('startBySchema', JSON.stringify(req));
      }
    }
    return '';
  } catch (e) {
    return {};
  }
};

const jumpToRoom = async function (gid: string) {
  console.log('gid', gid);
  try {
    if (gid !== '0' && gid !== '') {
      let req = {
        code: 0,
        res: `chatie://group/join?gid=${gid}`,
      };

      if (brower.isIphone()) {
        dsBridge.call('jumpToRoom', gid);
      } else {
        dsBridge.call('startBySchema', JSON.stringify(req));
      }
    }
    return '';
  } catch (e) {
    return '';
  }
};

const jumpToStore = async function (page: number) {
  try {
    let req = {
      code: 0,
      res: `chatie://app/store?index=${page}`,
    };

    if (brower.isIphone()) {
      dsBridge.call('gotoStore', page);
    } else {
      dsBridge.call('startBySchema', JSON.stringify(req));
    }
    return '';
  } catch (e) {
    return '';
  }
};

const jumpToUserStore = async function (page: number) {
  try {
    let req = {
      code: 0,
      res: `chatie://user/store?index=${page}`,
    };

    if (brower.isIphone()) {
      dsBridge.call('gotoUserStore', page);
    } else {
      dsBridge.call('startBySchema', JSON.stringify(req));
    }
    return '';
  } catch (e) {
    return '';
  }
};

// 新改 2023/05/29
const jumpToRoomNew = async function (gid: string) {
  console.log('gid', gid);
  try {
    if (gid !== '0' && gid !== '') {
      let req = {
        code: 0,
        res: `chatie://group/join?gid=${gid}`,
      };

      if (brower.isIphone()) {
        dsBridge.call('startBySchema', JSON.stringify(req));
      } else {
        dsBridge.call('startBySchema', JSON.stringify(req));
      }
    }
    return '';
  } catch (e) {
    return '';
  }
};

const jumpToWallet = async function (page: number) {
  try {
    let req = {
      code: 0,
      res: `chatie://app/wallet?index=${page}`,
    };

    if (brower.isIphone()) {
      dsBridge.call('startBySchema', JSON.stringify(req));
    } else {
      dsBridge.call('startBySchema', JSON.stringify(req));
    }
    return '';
  } catch (e) {
    return '';
  }
};

const jumpToTab = async function (page: number) {
  // page 参数
  // 首页 tabHome
  // 动态 tabMoment
  // 信息 tabMessage
  // 我的 tabMine
  console.log(page);
  try {
    let req = {
      code: 0,
      res: `chatie://main/page?tabName=${page}`,
    };
    if (brower.isIphone()) {
      dsBridge.call('startBySchema', JSON.stringify(req));
    } else {
      dsBridge.call('startBySchema', JSON.stringify(req));
    }
    return '';
  } catch (e) {
    console.error(e);
    return e;
  }
};

const jumpToGames = async function (page: number, gid: string) {
  // page
  let url = '';

  // 水果机
  if (page == 1) {
    url = window.location.origin + '/#/' + 'activity/fruit1103';
  }

  // 转盘
  if (page == 2) {
    url = '转盘';
  }

  // 扭蛋
  if (page == 3) {
    url = window.location.origin + '/#/' + 'app/gashapon';
  }

  // 调起礼物面板
  if (page == 4) {
    url = '礼物面板';
  }

  if (page == 5) {
    console.log('ludo');
    url = 'ludo';
  }

  try {
    if (page > 0) {
      let req = {
        code: 0,
        msg: 'success',
        res: {
          type: page,
          gid,
          url,
          width: 1080,
          height: 1512,
        },
      };
      if (brower.isIphone()) {
        dsBridge.call('onAppTaskRoute', JSON.stringify(req));
      } else {
        dsBridge.call('onAppTaskRoute', JSON.stringify(req));
      }
    }
    return '';
  } catch (e) {
    return e;
  }
};

// 跳转朋友
const jumpToFriend = async function () {
  try {
    let req = {
      code: 0,
      res: `chatie://friend/addNewFriend`,
    };
    if (brower.isIphone()) {
      dsBridge.call('startBySchema', JSON.stringify(req));
    } else {
      dsBridge.call('startBySchema', JSON.stringify(req));
    }
    return '';
  } catch (e) {
    console.error(e);
    return e;
  }
};

// 跳转福利页面
const jumpToWelfare = async function () {
  try {
    let req = {
      code: 0,
      res: window.origin + '/#/' + `activity/welfare`,
    };
    if (brower.isIphone()) {
      dsBridge.call('startBySchema', JSON.stringify(req));
    } else {
      dsBridge.call('startBySchema', JSON.stringify(req));
    }
    return '';
  } catch (e) {
    console.error(e);
    return e;
  }
};
// 新改 2023/05/29

// -------- jump前缀跳转app内的方法 --------

// -------- share前缀调起app内分享 --------

const shareToLink = async function (text: string) {
  try {
    if (text != '') {
      let req = {
        code: 0,
        msg: 'success',
        res: { type: 1, text: text },
      };
      if (brower.isIphone()) {
        dsBridge.call('appShare', JSON.stringify(req));
      } else {
        dsBridge.call('appShare', JSON.stringify(req));
      }
    }
    return '';
  } catch (e) {
    return {};
  }
};

const shareToWhatsapp = async function (text: string) {
  try {
    if (text != '') {
      let req = {
        code: 0,
        msg: 'success',
        res: { type: 2, text: text },
      };
      if (brower.isIphone()) {
        dsBridge.call('appShare', JSON.stringify(req));
      } else {
        dsBridge.call('appShare', JSON.stringify(req));
      }
    }
    return '';
  } catch (e) {
    return {};
  }
};

const copyNew = async function (tips: string, text: string) {
  try {
    if (text != '') {
      let req = {
        code: 0,
        msg: 'success',
        res: { type: 7, tips, text },
      };
      if (brower.isIphone()) {
        dsBridge.call('appShare', JSON.stringify(req));
      } else {
        dsBridge.call('appShare', JSON.stringify(req));
      }
    }
    return '';
  } catch (e) {
    return {};
  }
};

const shareToSnap = async function (text: string) {
  try {
    if (text != '') {
      let req = {
        code: 0,
        msg: 'success',
        res: { type: 3, text: text },
      };
      if (brower.isIphone()) {
        dsBridge.call('appShare', JSON.stringify(req));
      } else {
        dsBridge.call('appShare', JSON.stringify(req));
      }
    }
    return '';
  } catch (e) {
    return {};
  }
};

const shareInviteCode = async function (text: string) {
  try {
    if (text != '') {
      let req = {
        code: 0,
        msg: 'success',
        res: { type: 6, text: text },
      };
      if (brower.isIphone()) {
        dsBridge.call('appShare', JSON.stringify(req));
      } else {
        dsBridge.call('appShare', JSON.stringify(req));
      }
    }
    return '';
  } catch (e) {
    return {};
  }
};

const shareToIns = async function (text: string) {
  try {
    if (text != '') {
      let req = {
        code: 0,
        msg: 'success',
        res: { type: 4, text: text },
      };
      if (brower.isIphone()) {
        dsBridge.call('appShare', JSON.stringify(req));
      } else {
        dsBridge.call('appShare', JSON.stringify(req));
      }
    }
    return '';
  } catch (e) {
    return {};
  }
};

const shareToFaceBook = async function (text: string) {
  try {
    if (text != '') {
      let req = {
        code: 0,
        msg: 'success',
        res: { type: 5, text: text },
      };
      if (brower.isIphone()) {
        dsBridge.call('appShare', JSON.stringify(req));
      } else {
        dsBridge.call('appShare', JSON.stringify(req));
      }
    }
    return '';
  } catch (e) {
    return {};
  }
};

// -------- share前缀调起app内分享 --------

// 全关闭窗口
const closeWebViewPage = async function () {
  try {
    let req = {
      code: 0,
      res: {
        type: 0,
      },
    };

    if (brower.isIphone()) {
      dsBridge.call('closeWebViewPage', JSON.stringify(req)) || {}; //关闭webview
    } else {
      dsBridge.call('closeWebViewPage', req);
    }
  } catch (e) {
    return e;
  }
};

// 半屏幕窗口
const closeWebview = async function () {
  try {
    let req = {
      code: 0,
      res: {
        type: 0,
      },
    };

    if (brower.isIphone()) {
      dsBridge.call('closeWebview', JSON.stringify(req)) || {}; //水果机关闭半屏幕
    } else {
      dsBridge.call('closeWebview', JSON.stringify(req));
    }
  } catch (e) {
    return e;
  }
};

// 停止加载
const stopLoad = async function () {
  try {
    let req = {
      code: 0,
      res: {
        type: 0,
      },
    };

    if (brower.isIphone()) {
      dsBridge.call('stopLoading', JSON.stringify(req)) || {};
    } else {
      dsBridge.call('stopLoading', JSON.stringify(req));
    }
  } catch (e) {
    return e;
  }
};

// 选择图片
const chooseImage = async function (req: any, callback: any) {
  let pic = [];
  try {
    if (brower.isIphone()) {
      let goto = await versionCompare('1.3.14', 1);
      console.log('goto', goto); // 11-09前的版本有bug
      if (goto) {
        /* empty */
      } else {
        console.log('ios旧版本不兼容');
        return;
      }
    } else {
      let goto = await versionCompare(89, 2);
      console.log('goto', goto); // 11-09前的版本有bug
      if (goto) {
        /* empty */
      } else {
        console.log('安卓旧版本不兼容');
        return;
      }
    }

    let map = {
      count: req.count || 1, //可以选择的最多数量
      sizeType: req.sizeType || 'compressed', //大小压缩 original/compressed
      mp4Enabled: req.mp4Enabled, //是否可以选择mp4，true等于可以同时选mp4和图片
      ts: req.ts || 10,
    };

    await dsBridge.call('chooseImage', JSON.stringify(map), function (d) {
      console.log('原始回调内容', d); //应该有子节点  d.data.list  是图片内容
      if (brower.isIphone()) {
        /* empty */
      } else {
        d = JSON.parse(d);
      }

      if (d.length > 0 && d.length < 20) {
        for (let i = 0; i < d.length; i++) {
          pic.push(d[i]);
        }
      } else {
        console.log('选择的图片超过了20张', d.length);
      }
      callback && callback(pic);
    });
  } catch (e) {
    console.log('catch 错误', e);
  }
};

const uploadFile = async function (req: any, callback: any) {
  console.log('上传图片');
  try {
    if (brower.isIphone()) {
      let goto = await versionCompare('1.3.14', 1);
      // console.log('goto', goto) // 11-09前的版本有bug
      if (goto) {
        /* empty */
      } else {
        console.log('ios旧版本不兼容');
        return;
      }
    } else {
      let goto = await versionCompare(89, 2);
      console.log('goto', goto); // 2012-11-09前的版本有bug
      if (goto) {
        /* empty */
      } else {
        console.log('安卓旧版本不兼容');
        return;
      }
    }

    console.log('调用dsBridge');

    if (brower.isIphone()) {
      await dsBridge.call('uploadFile', JSON.stringify(req), function (d) {
        // console.log("上传回调") //应该有子节点  d.data.list  是图片内容
        console.log('上传成功', d);
        callback && callback(d);
      });
    } else {
      //安卓的旧版本上传有bug，会导致多个文件上传时，只有1个成功，其余全部被时间戳覆盖，等全部上传完成则一起返回

      let arr = [];
      let arrLength = 0;

      for (let i = 0; i < req.length; i++) {
        await dsBridge.call(
          'uploadFile',
          JSON.stringify([req[i]]),
          function (d) {
            // console.log("上传回调") //应该有子节点  d.data.list  是图片内容
            console.log('上传成功，图片序号', i, d);
            arrLength++;

            if (d != undefined && d != '') {
              d = JSON.parse(d);
              for (let i = 0; i < d.length; i++) {
                arr.push(d[i].url);
              }
            }

            if (arrLength >= req.length) {
              callback && callback(arr);
            }
          },
        );
      }
    }
  } catch (e) {
    console.log('catch 错误', e);
  }
};

// type  1-加载失败  2-加载中  3-加载成功  progress-加载进度
const WebviewOpen = async function (type: number) {
  try {
    let progress = 0;

    if (type == 2) {
      progress = 50;
    }

    if (type == 3) {
      progress = 100;
    }

    let req = {
      code: 0,
      res: {
        type: type,
        progress: progress,
      },
    };

    if (brower.isIphone()) {
      //ios没有实现
    } else {
      dsBridge.call('onFruitStateChanged', JSON.stringify(req));
    }
  } catch (e) {
    return e;
  }
};

/**
 * @description webView底部透明
 * @param {boolean} status 是否为透明
 * @returns
 */
const openViewTransparent = async function (status: number) {
  try {
    let req = {
      code: 0,
      msg: 'success',
      res: { isOpen: status },
    };
    if (brower.isIphone()) {
      dsBridge.call('changeWebViewBackgroundAlpha', JSON.stringify(req));
    } else {
      dsBridge.call('changeWebViewBackgroundAlpha', JSON.stringify(req));
    }
    return '';
  } catch (e) {
    return {};
  }
};

const createRoomSuccess = async function () {
  try {
    if (brower.isIphone()) {
      dsBridge.call('createRoomSuccess');
    } else {
      dsBridge.call('createRoomSuccess');
    }
    return '';
  } catch (e) {
    return {};
  }
};

/**
 * @description 调用原生媒体播放
 * @param {*} option {"loopUrl":"https://d2180mnhafnhva.cloudfront.net/welJc8IDZHhvPGWZWlWv9GKTq0WNKX9S.mp4","loop":1,"loopType":"mp4"}
 * loop ：是否循环播放 1循环  0不循环  loopType：播放类型string:  MP4 svga pic
 * @returns
 */
const toAppShow = async function (option: MediaOption) {
  try {
    if (brower.isIphone()) {
      dsBridge.call('toAppShow', JSON.stringify(option));
    } else {
      dsBridge.call('toAppShow', JSON.stringify(option));
    }
    return '';
  } catch (e) {
    return e;
  }
};

export default {
  getUserInfo,
  getUserDeviceInfo,
  getDeviceInfo,
  versionCompare,
  setTabBar,
  hiddenBackButton,
  jumpToFamily,
  jumpToUser,
  broadCast,
  jumpToRoom,
  jumpToStore,
  jumpToUserStore,
  jumpToRoomNew,
  jumpToWallet,
  jumpToTab,
  jumpToGames,
  jumpToFriend,
  jumpToWelfare,
  shareToLink,
  shareToWhatsapp,
  copyNew,
  shareToSnap,
  shareInviteCode,
  shareToIns,
  shareToFaceBook,
  closeWebViewPage,
  closeWebview,
  stopLoad,
  chooseImage,
  uploadFile,
  WebviewOpen,
  openViewTransparent,
  createRoomSuccess,
  toAppShow,
};
